* {
margin: 0;
padding: 0;
}


/* .main{
  width: 100%;
  height: 100vh;
} */


video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}